import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {MessagePanelProps} from '@Panels/message-panel';
import {PanelHeader} from '@Components/panel';
import {MODALS_IDS} from '@Components/modal-container';
import {useDispatch} from 'react-redux';
import {LazyPanel} from '@Components/lazy-panel';
import {Button, Type} from '@Components/button';
import {isUserLoggedIn, requestStorageAPIAccess} from '@Libraries/user.library';
import {openErrorModal} from '@Modals/error-modal';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'message-modal-panel';
const DEFAULT_MODAL_HEIGHT = '448px';
const DEFAULT_MODAL_WIDTH = '560px';
const AUTHENTICATION_LAYOVER_WINDOW_HEIGHT = 750;
const AUTHENTICATION_LAYOVER_WINDOW_WIDTH = 804;

interface EmbeddedEditorConsentModalCtaButton {
  text: string;
  onClick?: () => void;
  icon?: string;
}

export interface EmbeddedEditorConsentModalProps extends MessagePanelProps {
  title: string;
  primaryCta: EmbeddedEditorConsentModalCtaButton;
  secondaryCta?: EmbeddedEditorConsentModalCtaButton;
  height?: string;
  width?: string;
  showCookieConsentPrompt?: boolean;
  isUnclosable?: boolean;
  onClose?(): void | Promise<void>;
}

export function EmbeddedEditorConsentModal(): ReactElement {
  const panelProps = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.EMBEDDED_EDITOR_CONSENT_MODAL].panelProps as EmbeddedEditorConsentModalProps;
  });

  const dispatch = useDispatch();

  const height = panelProps.height ?? DEFAULT_MODAL_HEIGHT;
  const width = panelProps.width ?? DEFAULT_MODAL_WIDTH;

  const onModalClose = (): void => {
    if (panelProps.isUnclosable) {
      return;
    }

    dispatch(closeModal(MODALS_IDS.EMBEDDED_EDITOR_CONSENT_MODAL));
  };

  const openAuthenticationLayoverWindow = (): void => {
    const left = (window.screen.width - AUTHENTICATION_LAYOVER_WINDOW_WIDTH) / 2;
    const top = (window.screen.height - AUTHENTICATION_LAYOVER_WINDOW_HEIGHT) / 2;

    const windowFeatures = `width=${AUTHENTICATION_LAYOVER_WINDOW_WIDTH},height=${AUTHENTICATION_LAYOVER_WINDOW_HEIGHT},left=${left},top=${top},resizable,scrollbars=yes,status=1`;
    const authenticationUrl = `${window.PMW.util.site_url('authenticate/showlogin')}?redirect=${window.PMW.util.site_url('authenticate/embeddededitorlayover')}`;

    window.open(authenticationUrl, '_blank', windowFeatures);
  };

  const onAllowCookies = async (): Promise<void> => {
    try {
      await requestStorageAPIAccess();
      if (isUserLoggedIn()) {
        window.location.reload();
      } else {
        showAuthenticationError();
      }
    } catch (e) {
      console.error(
        'Failed to obtain storage access. Please make sure the storage-access permission is granted by your permission policy and that the editor is opened in a secure context (HTTPS)'
      );
      openEmbeddedEditorConsentModal(getAuthenticationLayoverPromptParams());
    }
    return Promise.resolve();
  };

  const showAuthenticationError = (): void => {
    openErrorModal({
      message: window.i18next.t('pmwjs_embedded_error_authentication_error'),
    });
  };

  const getAuthenticationLayoverPromptParams = (): EmbeddedEditorConsentModalProps => {
    return {
      title: window.i18next.t('pmwjs_login_to_edit'),
      textClassName: 'content-body',
      text: window.i18next.t('pmwjs_login_to_edit_design'),
      primaryCta: {
        text: window.i18next.t('pmwjs_log_in'),
        icon: 'icon-sign-in',
      },
      showCookieConsentPrompt: true,
      isUnclosable: true,
    };
  };

  const getCookieConsentPromptParams = (): EmbeddedEditorConsentModalProps => {
    return {
      title: window.i18next.t('pmwjs_allow_cookies_to_edit'),
      textClassName: 'content-body',
      text: window.i18next.t('pmwjs_allow_cookies_to_edit_design'),
      primaryCta: {
        text: window.i18next.t('pmwjs_allow_cookies'),
        onClick: (): void => {
          void onAllowCookies();
        },
      },
      showCookieConsentPrompt: true,
      isUnclosable: true,
    };
  };

  const onLogInClick = (): void => {
    openAuthenticationLayoverWindow();
    closeEmbeddedEditorConsentModal();

    if (panelProps.showCookieConsentPrompt) {
      openEmbeddedEditorConsentModal(getCookieConsentPromptParams());
    }
  };

  const shouldSecondaryCtaBeShown = (): boolean => {
    return panelProps.secondaryCta !== null && !panelProps.showCookieConsentPrompt;
  };

  const getPanelFooter = (): ReactElement => {
    return (
      <div className="flexbox -fullwidth spacing-m-l-10 spacing-m-r-10 spacing-m-b-8">
        {shouldSecondaryCtaBeShown() ? (
          <Button type={Type.SECONDARY} text={panelProps.secondaryCta?.text} customClasses="flexbox flex-1 spacing-m-r-3" onClick={closeEmbeddedEditorConsentModal} />
        ) : null}
        <Button
          type={Type.PRIMARY}
          text={panelProps.primaryCta.text}
          customClasses="flexbox flex-1"
          icon={panelProps.primaryCta.icon}
          onClick={panelProps.primaryCta.onClick ? panelProps.primaryCta.onClick : onLogInClick}
        />
      </div>
    );
  };

  return (
    <Modal modalId={MODALS_IDS.EMBEDDED_EDITOR_CONSENT_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="message-panel"
        panelProps={
          {
            ...panelProps,
            header: panelProps.header ?? <PanelHeader title={panelProps.title} onClose={onModalClose} />,
            footer: panelProps.footer ?? getPanelFooter(),
            panelId: PANEL_ID,
          } as MessagePanelProps
        }
      />
    </Modal>
  );
}

export const openEmbeddedEditorConsentModal = (props: EmbeddedEditorConsentModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.EMBEDDED_EDITOR_CONSENT_MODAL,
      panelProps: props,
    })
  );
};

export const closeEmbeddedEditorConsentModal = (): void => {
  window.PMW.redux.store.dispatch(closeModal(MODALS_IDS.EMBEDDED_EDITOR_CONSENT_MODAL));
};
