import type {ReactElement} from 'react';
import React from 'react';
import type {FlatIconType, IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Icon} from '../icon-v2';

interface ThumbnailProps {
  className?: string;
  onClick?(): void;
  data: ThumbnailData;
}

export type ThumbnailData = IconData | ImageData | CustomData;

interface ThumbnailBaseData {
  type: THUMBNAIL_TYPE;
}

interface IconData extends ThumbnailBaseData {
  disabled?: boolean;
  flat_icon_type?: FlatIconType;
  icon: string;
  IconShape?: IconShape;
  icon_size?: IconSize;
  icon_type?: IconType;
  type: THUMBNAIL_TYPE.ICON;
}

interface ImageData {
  type: THUMBNAIL_TYPE.IMAGE;
  imageSrc: string;
}

interface CustomData {
  type: THUMBNAIL_TYPE.CUSTOM;
  content: ReactElement;
}

export enum THUMBNAIL_TYPE {
  ICON = 'icon',
  IMAGE = 'image',
  CUSTOM = 'custom',
}

export function Thumbnail({className = '', onClick = (): void => {}, ...props}: ThumbnailProps): ReactElement {
  const displayThumbnail = (): ReactElement | null => {
    switch (props.data.type) {
      case THUMBNAIL_TYPE.ICON:
        return (
          <Icon
            onClick={onClick}
            className={`${className}`}
            isDisabled={props.data.disabled}
            icon={props.data.icon}
            type={props.data.icon_type}
            size={props.data.icon_size}
            shape={props.data.IconShape}
            flatIconType={props.data.flat_icon_type}
          />
        );
      case THUMBNAIL_TYPE.IMAGE:
        return <img onClick={onClick} className={className} src={props.data.imageSrc} alt={window.i18next.t('pmwjs_image_preview')} />;
      case THUMBNAIL_TYPE.CUSTOM:
        return <div className={className}>{props.data.content}</div>;
      default:
        return null;
    }
  };

  return <>{displayThumbnail()}</>;
}
