import {hexToRgb} from '@Utils/color.util';
import {dataURLToArray, loadImageAsync} from '@Utils/image.util';
import type {Canvas, FabricImage, FabricObject, StaticCanvas} from '@postermywall/fabricjs-2';
import {IText, loadSVGFromURL, Group, Point, Rect, util} from '@postermywall/fabricjs-2';
import type {CornerPoints, Position} from '@Utils/math.util';
import {degreesToRadians, roundPrecision, scaleDimensionsByMaxArea} from '@Utils/math.util';
import {arraysEqual} from '@Utils/array.util';

export interface FabricPositionalParams {
  left?: number;
  top?: number;
}

interface LoadSVGMetaData {
  width: number;
  height: number;
}

export interface LoadSVGDataResponse {
  paths: Array<FabricObject>;
  metaData: LoadSVGMetaData;
}

/**
 * This is used instead of standard compare because that returns true only if they refer to the exact same object
 * @param {Object} canvas
 * @param {string} cursor
 * @returns {boolean}
 */
export const setCanvasCursor = (canvas: Canvas, cursor: string): void => {
  canvas.defaultCursor = cursor;
  canvas.hoverCursor = cursor;
  canvas.moveCursor = cursor;
};

export const loadSVGDataFromURL = async (url: string): Promise<LoadSVGDataResponse> => {
  const svgParsed = await loadSVGFromURL(url);
  if (!svgParsed.objects || (Array.isArray(svgParsed.objects) && svgParsed.objects.length === 0)) {
    throw new Error(`Failed to load vector from url: ${url}`);
  }

  const objectsWithoutNulls = svgParsed.objects.filter((item) => {
    return item !== null;
  });

  return {
    paths: objectsWithoutNulls,
    metaData: svgParsed.options as LoadSVGMetaData,
  };
};

export const applyToChildObjects = (item: FabricObject, options: Record<string, any>): void => {
  if (item && 'getObjects' in item) {
    const objects = (item as Group).getObjects();
    objects.forEach((obj) => {
      applyToChildObjects(obj, options);
    });
  } else if (!(item instanceof IText && item.ignoreDelegatedSet)) {
    item.set(options);
  }
};

export const drawObjectToCanvas = async (ctx: CanvasRenderingContext2D, fabricObject: FabricObject | Group): Promise<void> => {
  const dimensions = scaleDimensionsByMaxArea(fabricObject.getScaledWidth(), fabricObject.getScaledHeight(), 200000000); // move this to a const
  const scale = roundPrecision(dimensions.width / fabricObject.getScaledWidth(), 3);

  const wqeqwe = fabricObject.toDataURL({
    format: 'png',
    multiplier: scale,
    quality: 1,
  });

  const maskingImageObject = await loadImageAsync(wqeqwe);

  fabricObject.setCoords();
  const centerPoints = fabricObject.getCenterPoint();
  ctx.drawImage(
    maskingImageObject,
    Math.floor(centerPoints.x - maskingImageObject.width / 2),
    Math.floor(centerPoints.y - maskingImageObject.height / 2),
    maskingImageObject.width * scale,
    maskingImageObject.height * scale
  );
};

/**
 * Gives data url for fabric object while considering toDataUrl limits
 */
export const fabricObjectToDataUrl = (fabricObject: FabricObject, toDataURLOptions: any = {}): string => {
  const fabricObjectScaleX = fabricObject.scaleX;
  const fabricObjectScaleY = fabricObject.scaleY;
  fabricObject.set({
    scaleX: 1,
    scaleY: 1,
  });
  const dataUrl = fabricObject.toDataURL(toDataURLOptions);
  fabricObject.set({
    scaleX: fabricObjectScaleX,
    scaleY: fabricObjectScaleY,
  });
  return dataUrl;
};

export const applyFillToFabricObject = (fabricObject: FabricObject | Group, color: string): void => {
  if ('getObjects' in fabricObject) {
    const objs = fabricObject.getObjects();
    for (let i = 0; i < objs.length; i++) {
      objs[i].set({
        fill: color,
      });
    }
  } else {
    fabricObject.set({
      fill: color,
    });
  }
};

export const doPathsHaveSameFill = (paths: FabricObject[]): boolean => {
  for (let i = 1; i < paths.length; i++) {
    const {fill} = paths[i];
    const fill2 = paths[i - 1].fill;
    if (typeof fill === 'object' || typeof fill2 === 'object') {
      return false;
    }

    const hex = fill.indexOf('#') > -1 ? hexToRgb(fill) : fill;
    const hex2 = fill2.indexOf('#') > -1 ? hexToRgb(fill2) : fill2;

    if (!arraysEqual(hex, hex2)) {
      return false;
    }
  }
  return true;
};

export const getPaths = (obj: FabricObject | Group): Array<FabricObject> => {
  let paths;
  if (obj instanceof Group) {
    paths = obj.getObjects();
  } else {
    paths = [obj];
  }

  return paths;
};

/**
 * Makes an Overlay image for the given fabric object using a white rectangle of 0.01 opacity
 */
export const getCopyProtectionOverlayImage = async (srcObj: FabricObject | Group, propertiesToCopy: Array<string> = []): Promise<FabricImage> => {
  const overlayRect = new Rect();
  overlayRect.set({
    left: srcObj.left + 1,
    top: srcObj.top + 1,
    width: srcObj.width - 1 || 1,
    height: srcObj.height - 1 || 1,
    scaleX: srcObj.scaleX || 1,
    scaleY: srcObj.scaleY || 1,
    fill: 'rgb(255, 255, 255)',
    strokeWidth: 0,
    padding: 0,
    opacity: 0.01,
  });
  return cloneAsAlignedImage(overlayRect, {}, propertiesToCopy);
};
/**
 * Extends cloneAsImage to also align and scale cloned item to original.
 * Scales item separately instead of passing multiplier to fabric function to avoid possible artefacts.
 */
export const cloneAsAlignedImage = async (srcObj: FabricObject | Group, opts: Record<string, any> = {}, propertiesToCopy: Array<string> = []): Promise<FabricImage> => {
  const doesSrcObjectHaveShadow = srcObj.shadow;

  const multiplier = (opts.multiplier as number) || 1;
  opts.multiplier = 1;

  scaleFabricObject(srcObj, multiplier, srcObj.strokeUniform);
  const angleInDegrees = srcObj.get('angle') as number;

  if (!doesSrcObjectHaveShadow) {
    srcObj.set({
      angle: 0,
    });
  }

  const clone = srcObj.cloneAsImage(opts);
  scaleFabricObject(srcObj, 1 / multiplier, srcObj.strokeUniform);
  scaleFabricObject(clone, 1 / multiplier);

  const increasedWidth = clone.width * clone.scaleX - srcObj.width * srcObj.scaleX - srcObj.strokeWidth;
  const xMovementToAdjustIncreasedWidth = increasedWidth * Math.cos(degreesToRadians(angleInDegrees));
  const yMovementToAdjustIncreasedWidth = increasedWidth * Math.sin(degreesToRadians(angleInDegrees));

  const increasedHeight = clone.height * clone.scaleY - srcObj.height * srcObj.scaleY - srcObj.strokeWidth;
  const xMovementToAdjustIncreasedHeight = increasedHeight * Math.cos(degreesToRadians(90 - angleInDegrees));
  const yMovementToAdjustIncreasedHeight = increasedHeight * Math.sin(degreesToRadians(90 - angleInDegrees));

  const leftAdjustment = yMovementToAdjustIncreasedHeight / 2 + yMovementToAdjustIncreasedWidth / 2;
  const topAdjustment = xMovementToAdjustIncreasedWidth / 2 - xMovementToAdjustIncreasedHeight / 2;

  clone.set({
    top: srcObj.top - leftAdjustment,
    left: srcObj.left - topAdjustment,
    angle: angleInDegrees,
  });

  srcObj.set({
    angle: angleInDegrees,
  });

  if (doesSrcObjectHaveShadow) {
    clone.rotate(0);
  }

  window.PMW.util.copyProperties(srcObj, clone, propertiesToCopy);
  clone.setCoords();
  return clone;
};

const scaleFabricObject = (fabricObj: FabricObject | Group, factor: number, scaleStroke = false): void => {
  fabricObj.scaleX *= factor;
  fabricObj.scaleY *= factor;
  if (fabricObj.hasStroke()) {
    fabricObj.strokeWidth *= scaleStroke ? factor : 1;
  }
  fabricObj.setCoords();
};

const alignItemTo = (targetFabricObj: FabricObject | Group, fabricObj: FabricObject | Group): void => {
  const angle = targetFabricObj.get('angle') as number;
  targetFabricObj.rotate(0);
  const ctrOffset = {
    x: fabricObj.getBoundingRect().width / 2 - targetFabricObj.getBoundingRect().width / 2,
    y: fabricObj.getBoundingRect().height / 2 - targetFabricObj.getBoundingRect().height / 2,
  };

  fabricObj.set('originX', targetFabricObj.get('originX'));
  fabricObj.set('originY', targetFabricObj.get('originY'));

  if ('left' in targetFabricObj) {
    fabricObj.left = targetFabricObj.left - ctrOffset.x;
  }
  if ('top' in targetFabricObj) {
    fabricObj.top = targetFabricObj.top - ctrOffset.y;
  }

  targetFabricObj.rotate(angle);
  fabricObj.setCoords();
};

export const cloneAsImageWithAngleIgnored = async (obj: FabricObject | Group, options: Record<string, any>): Promise<FabricObject> => {
  const angle = obj.get('angle') as number;
  obj.set('angle', 0);
  const clone = obj.cloneAsImage(options);
  obj.set('angle', angle);
  return clone;
};

export const isObjectVisible = async (obj: FabricObject | Group): Promise<boolean> => {
  if (obj) {
    const dataURL: string = obj.toDataURL();
    const dataArray: Uint8ClampedArray = await dataURLToArray(dataURL);
    const alphas = dataArray.filter((datum, i) => {
      return (i + 1) % 4 === 0;
    });

    return alphas.some((alpha) => {
      return alpha !== 0;
    });
  }
  return false;
};

/**
 * This function returns absolute corner points (with respect to canvas) of a group child object relative to the given center.
 */
export const getGroupChildAbsoluteCornerPoints = (groupChild: FabricObject): CornerPoints => {
  const fabricGroupParent = groupChild.group as Group;
  const {angle} = fabricGroupParent;
  const center = fabricGroupParent.getCenterPoint();
  let width = groupChild.getScaledWidth() * fabricGroupParent.scaleX;
  const height = groupChild.getScaledHeight() * fabricGroupParent.scaleY;

  // coordinates of the center point
  const {x, y} = center;
  const theta = degreesToRadians(angle);

  if (width < 0) {
    width = Math.abs(width);
  }

  const sinTh = Math.sin(theta);
  const cosTh = Math.cos(theta);
  const rotation = width > 0 ? Math.atan(height / width) : 0;
  const hypotenuse = width / Math.cos(rotation) / 2;
  const offsetX = Math.cos(rotation + theta) * hypotenuse;
  const offsetY = Math.sin(rotation + theta) * hypotenuse;

  return {
    tl: {
      x: x - offsetX,
      y: y - offsetY,
    },
    tr: {
      x: x - offsetX + width * cosTh,
      y: y - offsetY + width * sinTh,
    },
    bl: {
      x: x - offsetX - height * sinTh,
      y: y - offsetY + height * cosTh,
    },
    br: {
      x: x + offsetX,
      y: y + offsetY,
    },
  };
};

export const getPageOffsetOfPointOnFabricCanvasWithViewportTransform = (canvas: Canvas, point: Position): Position => {
  const {viewportTransform} = canvas;
  // Applying viewportTransform to the point's position
  const transformedPosition = util.transformPoint(new Point(point.left, point.top), viewportTransform);
  const {visualViewport} = window;
  if (!visualViewport) {
    return {left: 0, top: 0};
  }
  // Getting the position of the canvas on the page
  const canvasRect = canvas.getElement().getBoundingClientRect();
  const canvasLeft = canvasRect.left;
  const canvasTop = canvasRect.top;

  const pageOffsetLeft = canvasLeft + transformedPosition.x;
  const pageOffsetTop = canvasTop + transformedPosition.y;

  return {left: pageOffsetLeft, top: pageOffsetTop};
};

export const moveObjectOneLayerAboveAnotherObject = (canvas: Canvas | StaticCanvas, objToMoveInFront: FabricObject | Group, objToBeBehind: FabricObject | Group): void => {
  const canvasObjs = canvas.getObjects();
  const objToMoveInFrontZIndex = canvasObjs.indexOf(objToMoveInFront);
  const objToBeBehindZIndex = canvasObjs.indexOf(objToBeBehind);

  canvas.moveObjectTo(objToMoveInFront, objToMoveInFrontZIndex > objToBeBehindZIndex ? objToBeBehindZIndex + 1 : objToBeBehindZIndex);
};

/**
 * Fabric sets the scales of add item such that the final scale inculding its group equals to that
 * We don't want that for layouts so set group scale 1 so that the add items scale doesn't change and
 * then restore the group scale
 */
export const addItemsToGroupWithOriginalScale = (group: Group, items: Array<FabricObject>): void => {
  const {scaleX, scaleY, angle} = group;
  group.set({
    scaleX: 1,
    scaleY: 1,
    angle: 0,
  });
  group.add(...items);
  group.set({
    scaleX,
    scaleY,
    angle,
  });
};
