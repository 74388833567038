import type {SlideItem, SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {SlideItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {getScaledManualVerticalPadding} from '@PosterWhiteboard/items/item/item.library';
import type {FabricItemDimensions} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {TextSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/text-slide-item.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import type {ImageSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {addFonts, BULLETS_FONT_FAMILY, loadBulletsFont} from '@Libraries/font-library';
import {DEFAULT_FONT_SIZE} from '@PosterWhiteboard/page/add-item.class';
import {ITEM_CONTROL_DIMENSIONS} from '@PosterWhiteboard/poster/poster-item-controls';
import {DEFAULT_STROKE_WIDTH} from '@PosterWhiteboard/classes/text-styles.class';
import {POSTER_MAX_DURATION} from '@PosterWhiteboard/poster/poster.types';
import {createSlideItemFromObject} from '@PosterWhiteboard/items/item/item-factory';
import {MAX_ANIMATION_DURATION} from '@PosterWhiteboard/page/page.types';
import type {DeepPartial} from '@/global';

// SelfCodeReviewAhmed: need to see whhy PosterDurationLimits is NULL
// need to use PosterDurationLimits MAX_DURATION instead
export const MAX_SLIDESHOW_DURATION = 600 - MAX_ANIMATION_DURATION;
export const MIN_INTRO_DELAY = 0;
export const MIN_SLIDE_DURATION = 1;

export interface SlideshowSlidesObject {
  slidesHashMap: Record<string, SlideItemObject>;
  slidesOrder: Array<string>;
}

interface SlideDimensions {
  width: number;
  height: number;
}

export class SlideshowSlides {
  public slidesHashMap: Record<string, SlideItem> = {};
  public slidesOrder: Array<string> = [];
  public slideshow: SlideshowItem;

  public constructor(slideshow: SlideshowItem) {
    this.slideshow = slideshow;
  }

  public async updateFromObject(
    slidesObject: DeepPartial<SlideshowSlidesObject> = {},
    {updateRedux = true, undoable = true, doInvalidate = true}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    if (slidesObject.slidesOrder) {
      this.slidesOrder = slidesObject.slidesOrder;
    }

    // Update/Add items from slidesObject
    const updateFromObjectPromises = [];
    if (slidesObject.slidesHashMap) {
      const addItemPromises = [];

      for (const [uid, itemObject] of Object.entries(slidesObject.slidesHashMap)) {
        if (itemObject) {
          if (this.slidesHashMap[uid] !== undefined) {
            updateFromObjectPromises.push(
              this.slidesHashMap[uid].updateFromObject(itemObject, {
                updateRedux: false,
                undoable: false,
                doInvalidate,
              })
            );
          } else {
            addItemPromises.push(
              new Promise<void>((resolve, reject) => {
                showLoading(`addItem${uid}`);
                createSlideItemFromObject(this.slideshow.page, this.slideshow, itemObject)
                  .then(async (itemToAdd) => {
                    await this.scaleSlideIfNeeded(itemToAdd);
                    this.slideshow.addSlide(itemToAdd, {updateRedux: false, undoable: false});
                    resolve();
                  })
                  .catch(reject)
                  .finally(() => {
                    hideLoading(`addItem${uid}`);
                  });
              })
            );
          }
        }
      }

      const responses = await Promise.allSettled(addItemPromises);
      for (const response of responses) {
        if (response.status === 'rejected') {
          if (this.slideshow.page.poster.mode.isGeneration()) {
            console.log('Slideshow slides failed to add details:', response.reason);
            throw response.reason;
          }
          console.error('Slideshow slides failed to add details:', response);
        }
      }
    }

    // delete items that are in this page but not in the slidesObject
    for (const [uid] of Object.entries(this.slidesHashMap)) {
      if (slidesObject.slidesHashMap === undefined || slidesObject.slidesHashMap[uid] === undefined) {
        this.doRemoveItem(uid);
      }
    }

    await Promise.all(updateFromObjectPromises);
    await this.ensureFontsAreLoaded();

    if (undoable) {
      this.slideshow.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.slideshow.page.poster.redux.updateReduxData();
    }
  }

  public getLastSlide(): SlideItem {
    return this.slidesHashMap[this.slidesOrder[this.slidesOrder.length - 1]];
  }

  protected async ensureFontsAreLoaded(): Promise<void> {
    await this.loadFontFamiliesForTextSlides();
    await this.loadBulletFontForTextSlides();
  }

  protected async loadFontFamiliesForTextSlides(): Promise<void> {
    const fontFamilies: Array<string> = [];
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isTextSlide()) {
        const textSlideFontFamily = slide.textStyles.getFontFamilyToLoad();
        if (fontFamilies.indexOf(textSlideFontFamily) === -1) {
          fontFamilies.push(textSlideFontFamily);
        }
      }
    }

    if (fontFamilies.length > 0) {
      return new Promise((resolve, reject) => {
        addFonts(
          fontFamilies,
          () => {
            resolve();
          },
          reject
        );
      });
    }
    return Promise.resolve();
  }

  protected async loadBulletFontForTextSlides(): Promise<void> {
    if (this.doesTextSlidesHaveBulletFont()) {
      return new Promise((resolve) => {
        loadBulletsFont(BULLETS_FONT_FAMILY, () => {
          resolve();
        });
      });
    }
    return Promise.resolve();
  }

  protected doesTextSlidesHaveBulletFont(): boolean {
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isTextSlide() && slide.list.hasList()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Only scale the slide if it's a media slide, and it's the first slide in the slideshow.
   */
  private async scaleSlideIfNeeded(slideItem: SlideItem): Promise<void> {
    if ((this.slideshow.width === 0 || this.slideshow.height === 0) && slideItem.isMediaSlide()) {
      await this.slideshow.page.items.addItems.scaleItemToPosterDimension(slideItem);
    }
  }

  public moveSlideToIndex(slideId: string, newIndex: number): void {
    const newSlidesOrder = [...this.slidesOrder];
    const oldIndex = this.slidesOrder.indexOf(slideId);
    newSlidesOrder.splice(oldIndex, 1);
    newSlidesOrder.splice(newIndex, 0, slideId);

    this.slidesOrder = newSlidesOrder;
    this.seekToSlideIndex(newIndex);
    this.slideshow.restoreDefaultState();

    this.slideshow.page.poster.history.addPosterHistory();
    this.slideshow.page.poster.redux.updateReduxData();
  }

  public hasSlide(uid: string): boolean {
    return this.slidesHashMap[uid] !== undefined;
  }

  private doRemoveItem(itemUid: string): void {
    if (this.hasSlide(itemUid)) {
      this.slidesHashMap[itemUid].onRemove();
      this.slideshow.fabricObject.remove(this.slidesHashMap[itemUid].fabricObject as Fabric.Object);
      delete this.slidesHashMap[itemUid];

      if (this.slideshow.selectedSlideUID === itemUid) {
        this.slideshow.setSelectedSlide(this.getFirstSlide().uid);
      }

      const selectedSlideIndex = this.getSlideIndex(this.slideshow.selectedSlideUID);
      if (selectedSlideIndex !== undefined) {
        this.seekToSlideIndex(selectedSlideIndex);
      }
    }
  }

  public addSlide(newSlide: SlideItem): void {
    this.slidesHashMap[newSlide.uid] = newSlide;
  }

  public toObject(): SlideshowSlidesObject {
    const slideObjects: Record<string, any> = {};

    for (const [key, slide] of Object.entries(this.slidesHashMap)) {
      slideObjects[key] = slide.toObject();
    }

    return {
      slidesHashMap: slideObjects,
      slidesOrder: [...this.slidesOrder],
    };
  }

  public getMaxDimensionsOfTextSlides(): SlideDimensions {
    let maxSlideWidth = 0;
    let maxSlideHeight = 0;
    let itemHeight = 0;
    let itemWidth = 0;
    let slideFabricObject;

    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isTextSlide()) {
        slideFabricObject = slide.fabricObject;

        // if new text slide isn't the first and only slide, take slideshow dimensions (don't want smaller new text slide to reduce overall slideshow dimensions)
        if (slide.isNewSlide && this.slideshow.width !== 0 && this.slideshow.height !== 0) {
          itemWidth = this.slideshow.width;
          itemHeight = this.slideshow.height;
        } else {
          itemWidth = slideFabricObject.getScaledWidth();
          itemHeight = slideFabricObject.getScaledHeight();
        }

        if (itemWidth > maxSlideWidth) {
          maxSlideWidth = itemWidth;
        }

        if (itemHeight > maxSlideHeight) {
          maxSlideHeight = itemHeight;
        }
      }
    }

    return {
      width: maxSlideWidth,
      height: maxSlideHeight,
    };
  }

  public getMaxDimensionsOfMediaSlides(): FabricItemDimensions {
    let maxSlideWidth = 0;
    let maxSlideHeight = 0;
    let itemHeight = 0;
    let itemWidth = 0;

    if (this.hasMediaSlide()) {
      for (const [, slide] of Object.entries(this.slidesHashMap)) {
        if (slide.isMediaSlide()) {
          itemWidth = slide.fabricObject.getScaledWidth();

          if (itemWidth > maxSlideWidth) {
            maxSlideWidth = itemWidth;
          }

          itemHeight = slide.fabricObject.getScaledHeight();

          if (itemHeight > maxSlideHeight) {
            maxSlideHeight = itemHeight;
          }
        }
      }
    }
    return {
      width: maxSlideWidth,
      height: maxSlideHeight,
    };
  }

  // TODO: See why these two params are being used and fix accordingly
  public updateSlideDimensions(): void {
    const slideShowWidth = this.slideshow.fabricObject.width;
    const slideShowHeight = this.slideshow.fabricObject.height;

    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isTextSlide()) {
        if (slide.isNewSlide) {
          slide.isNewSlide = false;
        }

        if (slide.fabricObject.getScaledWidth() !== slideShowWidth) {
          slide.fabricObject.set({
            width: slideShowWidth,
          });
          slide.updateTextChildWidth();
          // if (whiteboard) {
          //   slide.baseWidth = slide.fabricTextbox.width;
          //   void slide.updateFabricObject();
          // }
        }

        if (slide.fabricObject.getScaledHeight() !== slideShowHeight) {
          // if (updatedModel) {
          slide.verticalPadding = slideShowHeight - getScaledManualVerticalPadding(this.slideshow.fabricObject.scaleY) - slide.getTextStrokedHeight();
          // }
          slide.fabricObject.set({
            height: slideShowHeight,
          });
          slide.setBackgroundParams();
        }
      } else {
        this.scaleMediaSlide(slide.uid);
      }
    }
  }

  public scaleMediaSlide(slideId: string): void {
    if (!this.slideshow.page.poster.mode.isGeneration()) {
      const mediaSlide = this.slidesHashMap[slideId] as ImageSlideItem | VideoSlideItem;
      mediaSlide.mediaSlide.updateMediaSlideScale();
    }
  }

  public updateSlidePositions(): void {
    const slideShowWidth = this.slideshow.fabricObject.width;
    const slideShowHeight = this.slideshow.fabricObject.height;

    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isTextSlide()) {
        slide.fabricObject.set({
          left: -slideShowWidth / 2,
          top: -slideShowHeight / 2,
        });

        slide.setTextChildPosition();
      } else {
        slide.fabricObject.set(slide.mediaSlide.getMediaSlidePosition());
      }
    }
  }

  public isSlideEditable(slideId: string): boolean {
    const slideIndex = this.getSlideIndex(slideId);
    if (slideIndex !== undefined) {
      const slideModel = this.slidesHashMap[this.slidesOrder[slideIndex]];
      return slideModel.isTextSlide() && slideModel.editable;
    }
    return false;
  }

  public getUnpuchasedGettyImageSlidesCount(): number {
    let count = 0;
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isImageSlide() && slide.isNonPurchasedGetty()) {
        count += 1;
      }
    }
    return count;
  }

  public hasVideoSlide(): boolean {
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isVideoSlide()) {
        return true;
      }
    }
    return false;
  }

  public hasTextSlide(): boolean {
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isTextSlide()) {
        return true;
      }
    }
    return false;
  }

  public hasMediaSlide(): boolean {
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isMediaSlide()) {
        return true;
      }
    }
    return false;
  }

  public hasGettyImageSlide(): boolean {
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isImageSlide() && slide.hasGettyContent()) {
        return true;
      }
    }
    return false;
  }

  public hasGettyVideoSlide(): boolean {
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isVideoSlide() && slide.hasGettyContent()) {
        return true;
      }
    }
    return false;
  }

  public getGettyVideoSlides(): Array<VideoSlideItem> {
    const gettyVideoSlides: Array<VideoSlideItem> = [];
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isVideoSlide() && slide.hasGettyContent()) {
        gettyVideoSlides.push(slide);
      }
    }
    return gettyVideoSlides;
  }

  public getVideoSlides(): Array<VideoSlideItem> {
    const videoSlides: Array<VideoSlideItem> = [];
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isVideoSlide()) {
        videoSlides.push(slide);
      }
    }
    return videoSlides;
  }

  public getGettyVideoSlidesCount(): number {
    let count = 0;
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      if (slide.isVideoSlide() && slide.hasGettyContent()) {
        count += 1;
      }
    }
    return count;
  }

  /**
   * Whether the slideshow item is premium or not
   */
  public isPremium(): boolean {
    return this.getUnpuchasedGettyImageSlidesCount() > 0 || this.hasGettyVideoSlide();
  }

  /**
   * Returns the last text slide if it exists
   */
  public getLastTextSlide(): TextSlideItem | undefined {
    for (let i = this.slidesOrder.length - 1; i >= 0; i--) {
      const slide = this.slidesHashMap[this.slidesOrder[i]];
      if (slide.isTextSlide()) {
        return slide;
      }
    }
    return undefined;
  }

  /**
   * Returns the duration of last media slide
   */
  public getLastMediaSlideDuration(): number | undefined {
    for (let i = this.slidesOrder.length - 1; i >= 0; i--) {
      const slide = this.slidesHashMap[this.slidesOrder[i]];
      if (slide.isMediaSlide()) {
        return slide.slideDuration;
      }
    }
    return undefined;
  }

  /**
   * Returns the time at which the slide starts playing
   * @param {string} slideId
   * @returns {number|null}
   */
  public getStartTimeForSlide(slideId: string): number | undefined {
    let slideshowTime = this.slideshow.introAnimationPadding + this.slideshow.introDelay;
    for (const id of this.slidesOrder) {
      if (slideId === id) {
        return slideshowTime;
      }
      slideshowTime += this.slidesHashMap[id].getSlideDuration();
    }
    return undefined;
  }

  /**
   * Returns the time of the poster when the slide ends after transitioning out.
   */
  public getEndTimeForSlide(slideId: string): number | undefined {
    const slideIndex = this.getSlideIndex(slideId);
    const startTimeForSlide = this.getStartTimeForSlide(slideId);
    if (startTimeForSlide !== undefined && slideIndex !== undefined) {
      return startTimeForSlide + this.slidesHashMap[this.slidesOrder[slideIndex]].getSlideDuration();
    }
    return undefined;
  }

  /**
   * Returns the time of poster for the slide after it has been transitioned in.
   */
  public getTimeForTransitionedSlide(slideId: string): number {
    // Don't add the transition delay duration if its the first slide with no intro delay and introOutroTransition option is false for it too
    return (
      (this.getStartTimeForSlide(slideId) ?? 0) +
      (this.getFirstSlide().uid !== slideId || this.slideshow.hasIntroOutroTransition || this.slideshow.hasIntroDelay() ? this.slideshow.getTransitionDuration() : 0)
    );
  }

  public isLastSlide(slideId: string): boolean {
    return this.getSlideIndex(slideId) === Object.keys(this.slidesHashMap).length - 1;
  }

  /**
   * Returns the slide index of the model for the time.
   */
  getSlideIndexForTime(time: number): number {
    const numberOfSlides = Object.keys(this.slidesHashMap).length;
    const slideShowDuration = this.slideshow.getDuration();
    let slideshowTime = this.slideshow.introAnimationPadding + this.slideshow.introDelay;

    let index = 0;

    for (const id of this.slidesOrder) {
      const slide = this.slidesHashMap[id];
      slideshowTime += slide.getSlideDuration();
      if (slideshowTime > time) {
        return index;
      }
      if (time >= slideShowDuration) {
        return numberOfSlides - 1;
      }
      index += 1;
    }
    return index;
  }

  public getSlideIndex(slideId: string): number | undefined {
    const slideOrder = this.slidesOrder.indexOf(slideId);
    return slideOrder !== -1 ? slideOrder : undefined;
  }

  public getSlideForId(slideId: string): SlideItem | undefined {
    return this.slidesHashMap[slideId];
  }

  public isFirstSlideNonEmptyText(): boolean {
    const firstSlide = this.slidesHashMap[this.slidesOrder[0]];
    return firstSlide.isTextSlide() && !firstSlide.isTextEmpty();
  }

  public getFirstSlide(): SlideItem {
    return this.slidesHashMap[this.slidesOrder[0]];
  }

  public seekToSlideIndex(slideIndex: number): void {
    if (slideIndex !== undefined) {
      const selectedSlideTime = this.getTimeForTransitionedSlide(this.slidesHashMap[this.slidesOrder[slideIndex]].uid);
      void this.slideshow.page.poster.seek(selectedSlideTime);
    }
  }

  public seekToSlideId(slideId: string): void {
    if (this.slidesHashMap[slideId]) {
      const selectedSlideTime = this.getTimeForTransitionedSlide(this.slidesHashMap[slideId].uid);
      void this.slideshow.page.poster.seek(selectedSlideTime);
    }
  }

  public getMaxValidDuration(slideId?: string): number {
    let sumOfOtherSlides = 0;
    for (let index = 0; index < this.slidesOrder.length; index += 1) {
      if (!slideId || this.slidesOrder[index] !== slideId) {
        sumOfOtherSlides += this.slidesHashMap[this.slidesOrder[index]].getSlideDuration();
      }
    }

    return Math.floor(getMaxSlideshowDuration() - sumOfOtherSlides - this.slideshow.introDelay);
  }

  /**
   * Returns the possible index of the slide after the currently selected slide.
   * @returns {number|null}
   */
  public getNextSlideIndex(): number | undefined {
    const nextSlideIndex = (this.getSlideIndex(this.slideshow.selectedSlideUID) ?? 0) + 1;
    return nextSlideIndex < Object.keys(this.slidesHashMap).length ? nextSlideIndex : undefined;
  }

  /**
   * Returns the possible index of the slide before the currently selected slide.
   */
  public getPreviousSlideIndex(): number | undefined {
    const previousSlideIndex = (this.getSlideIndex(this.slideshow.selectedSlideUID) ?? 0) - 1;
    return previousSlideIndex >= 0 ? previousSlideIndex : undefined;
  }

  /**
   * Returns true if the currently selected slide is not the last slide of the slideshow.
   */
  public hasNextSlide(): boolean {
    return this.getNextSlideIndex() !== undefined;
  }

  /**
   * Returns true if the currently selected slide is not the last slide of the slideshow.
   * @returns {boolean}
   */
  public hasPreviousSlide(): boolean {
    return this.getSlideIndex(this.slideshow.selectedSlideUID) !== 0;
  }

  /**
   * Seeks the poster according to the duration of slide with slideID
   */
  public seekPosterToSlide(slideId: string): void {
    // if (!postermywall.Core.isPosterGenerating) {
    const selectedSlideTime = this.getTimeForTransitionedSlide(slideId);
    void this.slideshow.page.poster.seek(selectedSlideTime);
    // }
  }

  /**
   * Adds the new slide's viewComponent to the slideshow group and updates the viewCompoent.
   */
  public addSlideToGroupViewComponent(slideViewComponent: Fabric.Object): void {
    slideViewComponent.set({
      left: this.slideshow.fabricObject.left,
      top: this.slideshow.fabricObject.top,
    });

    this.slideshow.fabricObject.add(slideViewComponent);
  }

  /**
   * Removes the slide's viewComponent from the slideshow group and updates the viewCompoent.
   */
  public removeSlideFromGroupViewComponent(slideViewComponent: Fabric.Object): void {
    this.slideshow.fabricObject.remove(slideViewComponent);
  }

  /**
   * Sets the opacity of all slides to 0.
   */
  public hideAllSlides(): void {
    for (const [, slide] of Object.entries(this.slidesHashMap)) {
      slide.fabricObject.set({opacity: 0});
    }
  }

  /**
   * Sets the opacity of the first slide to 1.
   */
  public showFirstSlide(): void {
    this.getFirstSlide().fabricObject.set({opacity: 1});
  }

  public async getNewTextSlideItem(): Promise<SlideItem> {
    const widthOfThePoster = this.slideshow.page.poster.width;
    const heightOfThePoster = this.slideshow.page.poster.height;
    const fontSizeLowerLimit = widthOfThePoster > heightOfThePoster ? widthOfThePoster / DEFAULT_FONT_SIZE / 2 : heightOfThePoster / DEFAULT_FONT_SIZE / 2;
    const fontSize = Math.max(widthOfThePoster < heightOfThePoster ? widthOfThePoster / DEFAULT_FONT_SIZE : heightOfThePoster / DEFAULT_FONT_SIZE, fontSizeLowerLimit);

    return createSlideItemFromObject(this.slideshow.page, this.slideshow, {
      gitype: ITEM_TYPE.TEXTSLIDE,
      text: '',
      textStyles: {fontSize},
      width: this.slideshow.width,
      height: this.slideshow.height,
      baseWidth: this.slideshow.width - ITEM_CONTROL_DIMENSIONS.PMW_CONTROL_PADDING * 2 - DEFAULT_STROKE_WIDTH,
      isNewSlide: true,
    });
  }

  public getNumberOfSlides(): number {
    return Object.keys(this.slidesHashMap).length;
  }

  public getNumberOfTextSlides(): number {
    const slides = Object.values(this.slidesHashMap);
    let numberOfTextSlides = 0;
    for (const eachSlide of slides) {
      if (eachSlide.isTextSlide()) {
        numberOfTextSlides += 1;
      }
    }

    return numberOfTextSlides;
  }

  public getNumberOfImageSlides(): number {
    const slides = Object.values(this.slidesHashMap);
    let numberOfImageSlides = 0;
    for (const eachSlide of slides) {
      if (eachSlide.isImageSlide()) {
        numberOfImageSlides += 1;
      }
    }

    return numberOfImageSlides;
  }

  public getNumberOfVideoSlides(): number {
    const slides = Object.values(this.slidesHashMap);
    let numberOfVideoSlides = 0;
    for (const eachSlide of slides) {
      if (eachSlide.isVideoSlide()) {
        numberOfVideoSlides += 1;
      }
    }

    return numberOfVideoSlides;
  }
}

export const getMaxSlideshowDuration = (): number => {
  return POSTER_MAX_DURATION - MAX_ANIMATION_DURATION;
};
