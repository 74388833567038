import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {GridItemStorage} from '@Components/base-grid/components/grid-item';

interface StateProps {
  collection: string;
  isFbPanelOpen: boolean;
  unlinkedMedia: Array<GridItemStorage>;
}

const initialState: StateProps = {
  collection: '',
  isFbPanelOpen: false,
  unlinkedMedia: [],
};

const uploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    updateCurrentCollection: (state, action: PayloadAction<string>) => {
      state.collection = action.payload;
    },
    toggleIsFbPanelOpenState: (state, action: PayloadAction<boolean>) => {
      state.isFbPanelOpen = action.payload;
    },
    updateUnlinkedMediaState: (state, action: PayloadAction<Array<GridItemStorage>>) => {
      state.unlinkedMedia = [...state.unlinkedMedia, ...action.payload];
    },
  },
});

export const {updateCurrentCollection, toggleIsFbPanelOpenState, updateUnlinkedMediaState} = uploadsSlice.actions;
export const uploadsReducer = uploadsSlice.reducer;
