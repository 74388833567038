import type {Page} from '@PosterWhiteboard/page/page.class';
import type {ItemObject, ItemType} from '@PosterWhiteboard/items/item/item.types';
import {cloneDeep} from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {Alignment} from '@PosterWhiteboard/page/alignment.class';
import {getFabricObjectUID} from '@PosterWhiteboard/items/item/item.class';
import {degreesToRadians} from '@Utils/math.util';
import {FONT_SIZE_MAX, FONT_SIZE_MIN} from '@PosterWhiteboard/items/text-item/text-item.types';
import type {CanvasEvents, FabricObject} from '@postermywall/fabricjs-2';
import {Group, ActiveSelection, Textbox, Canvas} from '@postermywall/fabricjs-2';
import {AudioItem} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';

export enum GroupSpacing {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum GroupSpacingAxis {
  X = 'x',
  Y = 'y',
}

const RESIZE_SELECTION_SCALE = {
  DEFAULT: 0.04,
  TEXT: 4,
};

export enum ResizeSelectionKey {
  UP = 'up',
  DOWN = 'down',
}

export class SelectionManager {
  public page: Page;
  public alignment: Alignment;

  public constructor(page: Page) {
    this.page = page;
    this.alignment = new Alignment(this.page);
  }

  public onSelectionCreated(): void {
    const o = this.getActiveObject();
    if (o instanceof ActiveSelection) {
      const selectedViews = this.getSelectedViews() as Array<FabricObject | Group>;

      for (let i = 0; i < selectedViews.length; i++) {
        const itemId = getFabricObjectUID(selectedViews[i]);
        const item = this.page.items.getItem(itemId);
        if (selectedViews[i] instanceof Textbox && item && (item?.isText() || item?.isSlideshow())) {
          selectedViews[i] = item.fabricObject;
          this.discardActiveObject();

          const selectedObjects = new ActiveSelection(selectedViews, {
            canvas: this.page.fabricCanvas,
          });
          this.setActiveObject(selectedObjects);
        }
      }
    }
  }

  public onViewModified(e: CanvasEvents['object:modified']): void {
    if (e?.target) {
      const {target} = e;
      if (target instanceof ActiveSelection) {
        this.updateVisuals(target.getObjects(), target).catch((error) => {
          console.error(error);
        });
      }
    }
  }

  public async updateVisuals(activeObjects: Array<FabricObject>, group: Group, undoable = true): Promise<void> {
    const promises = [];

    for (const activeObject of activeObjects) {
      const itemId = getFabricObjectUID(activeObject as FabricObject | Group);
      const item = this.page.items.itemsHashMap[itemId];
      if (item) {
        const changes = this.getUpdatedItemVisual(activeObject, group);
        promises.push(
          item.updateFromObject(changes, {
            undoable: false,
          })
        );
      }
    }

    await Promise.all(promises);
    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
  }

  protected getUpdatedItemVisual(view: FabricObject, group: Group): Partial<ItemObject> {
    let p;
    let b;
    let radius;
    let initialAngle = 0;
    let angle = 0;
    let rotation = view.angle;
    if (group) {
      // calculating radius and angle from center of the group to the object
      p = Math.abs(view.left * group.scaleX + (group.width * group.scaleX) / 2);
      b = Math.abs(view.top * group.scaleY + (group.height * group.scaleY) / 2);
      radius = Math.hypot(p, b);
      initialAngle = Math.atan(b / p);
      if (Number.isNaN(initialAngle)) {
        initialAngle = 0;
      }
      angle = initialAngle + degreesToRadians(group.angle); // calculating total angle for object which is equal to its initialAngle and the group angle
      rotation = group.angle + view.angle >= 360 ? group.angle + view.angle - 360 : group.angle + view.angle;
    }
    return {
      x: group ? group.left + (radius ?? 0) * Math.cos(angle) : view.left,
      y: group ? group.top + (radius ?? 0) * Math.sin(angle) : view.top,
      width: view.width,
      height: view.height,
      rotation,
      scaleX: group ? group.scaleX * view.scaleX : view.scaleX,
      scaleY: group ? group.scaleY * view.scaleY : view.scaleY,
      flipX: view.flipX,
      flipY: view.flipY,
    };
  }

  public clearSelection(): void {
    const fabricObjects = this.getActiveObjects();
    if (!fabricObjects || fabricObjects.length === 0) {
      return;
    }

    this.discardActiveObject();
    this.page.fabricCanvas.requestRenderAll();
  }

  public removeItemFromSelection(item: FabricObject): void {
    const fabricObjects = this.getActiveObjects();
    if (!fabricObjects || fabricObjects.length === 0) {
      return;
    }

    this.selectFabricObjects(
      fabricObjects.filter((fabricObject) => {
        return fabricObject !== item;
      })
    );
  }

  public deleteSelectedItems(): void {
    const fabricObjects = this.getActiveObjects();
    if (!fabricObjects || fabricObjects.length === 0) {
      return;
    }

    const uids: Array<string> = [];

    for (let index = 0; index < fabricObjects.length; index += 1) {
      uids.push(fabricObjects[index].__PMWID);
    }

    this.page.poster.deleteItemByIds(uids);
    this.clearSelection();
  }

  private sortByXaxis(a: FabricObject, b: FabricObject): number {
    const aCor = a.getCornerPoints(a.getCenterPoint());
    const bCor = b.getCornerPoints(b.getCenterPoint());

    const aMin = Math.round(Math.min(aCor.tl.x, aCor.tr.x, aCor.br.x, aCor.bl.x));
    const bMin = Math.round(Math.min(bCor.tl.x, bCor.tr.x, bCor.br.x, bCor.bl.x));

    if (aMin < bMin) return -1;
    if (aMin > bMin) return 1;
    return 0;
  }

  private getFilledSpace(views: Array<FabricObject>, group: Group, axis: GroupSpacingAxis): number {
    let filledSpace = 0;
    const firstItemCorners = views[0].getCornerPoints(views[0].getCenterPoint());
    const lastItemCorners = views[views.length - 1].getCornerPoints(views[views.length - 1].getCenterPoint());
    const firstItemMin = Math.min(firstItemCorners.tl[axis], firstItemCorners.tr[axis], firstItemCorners.br[axis], firstItemCorners.bl[axis]);
    const lastItemMax = Math.max(lastItemCorners.tl[axis], lastItemCorners.tr[axis], lastItemCorners.br[axis], lastItemCorners.bl[axis]);
    let padding: number;
    let min: number;
    let max: number;

    if (axis === GroupSpacingAxis.X) {
      padding = firstItemMin + group.width / 2 + (group.width / 2 - lastItemMax);
    } else {
      padding = firstItemMin + group.height / 2 + (group.height / 2 - lastItemMax);
    }

    if (padding > group.padding * 3) {
      padding = group.padding * 2;
      if (axis === GroupSpacingAxis.X) {
        views[0].set({
          left: padding / 2 + (views[0].left - firstItemMin) - group.width / 2,
        });
      } else if (GroupSpacingAxis.Y) {
        views[0].set({
          top: padding / 2 + (views[0].top - firstItemMin) - group.height / 2,
        });
      }
    }

    for (let index = 0; index < views.length; index += 1) {
      const corners = views[index].getCornerPoints(views[index].getCenterPoint());
      min = Math.min(corners.tl[axis], corners.tr[axis], corners.br[axis], corners.bl[axis]);
      max = Math.max(corners.tl[axis], corners.tr[axis], corners.br[axis], corners.bl[axis]);
      filledSpace += max - min;
    }
    return filledSpace + padding;
  }

  private sortByYaxis(a: FabricObject, b: FabricObject): number {
    const aCor = a.getCornerPoints(a.getCenterPoint());
    const bCor = b.getCornerPoints(b.getCenterPoint());

    const aMin = Math.round(Math.min(aCor.tl.y, aCor.tr.y, aCor.br.y, aCor.bl.y));
    const bMin = Math.round(Math.min(bCor.tl.y, bCor.tr.y, bCor.br.y, bCor.bl.y));

    if (aMin < bMin) return -1;
    if (aMin > bMin) return 1;
    return 0;
  }

  private async onVerticalGroupSpacing(): Promise<void> {
    const group = this.getActiveObject();
    const views = this.getActiveObjects();

    if (!views || !(group instanceof Group)) {
      return;
    }

    views.sort((a: FabricObject, b: FabricObject): number => {
      return this.sortByXaxis(a, b);
    });

    const filledSpace = this.getFilledSpace(views, group, GroupSpacingAxis.X);
    const emptySpace = group.width - filledSpace;
    const spaceBetweenEachItem = emptySpace / (views.length - 1);

    for (let index = 1; index < views.length; index += 1) {
      views[index].set({
        left: views[index - 1].left,
      });

      const prevItemCorners = views[index - 1].getCornerPoints(views[index - 1].getCenterPoint());
      const nextItemCorners = views[index].getCornerPoints(views[index].getCenterPoint());
      const prevMax = Math.max(prevItemCorners.tl.x, prevItemCorners.tr.x, prevItemCorners.br.x, prevItemCorners.bl.x);
      const nextMin = Math.min(nextItemCorners.tl.x, nextItemCorners.tr.x, nextItemCorners.br.x, nextItemCorners.bl.x);
      views[index].set({
        left: views[index].left + (prevMax - views[index - 1].left) + spaceBetweenEachItem + (views[index].left - nextMin),
      });
    }
    await this.updateVisuals(views, group);
  }

  private async onHorizontalSpacing(): Promise<void> {
    const group = this.getActiveObject();
    const views = this.getActiveObjects();

    if (!views || !(group instanceof Group)) {
      return;
    }

    views.sort((a: FabricObject, b: FabricObject): number => {
      return this.sortByYaxis(a, b);
    });

    const filledSpace = this.getFilledSpace(views, group, GroupSpacingAxis.Y);
    const emptySpace = group.height - filledSpace;
    const spaceBetweenEachItem = emptySpace / (views.length - 1);

    for (let index = 1; index < views.length; index += 1) {
      views[index].set({
        top: views[index - 1].top,
      });

      const prevItemCorners = views[index - 1].getCornerPoints(views[index - 1].getCenterPoint());
      const nextItemCorners = views[index].getCornerPoints(views[index].getCenterPoint());
      const prevMax = Math.max(prevItemCorners.tl.y, prevItemCorners.tr.y, prevItemCorners.br.y, prevItemCorners.bl.y);
      const nextMin = Math.min(nextItemCorners.tl.y, nextItemCorners.tr.y, nextItemCorners.br.y, nextItemCorners.bl.y);
      views[index].set({
        top: views[index].top + (prevMax - views[index - 1].top) + spaceBetweenEachItem + (views[index].top - nextMin),
      });
    }
    await this.updateVisuals(views, group);
  }

  public onGroupSpacing(type: GroupSpacing): void {
    switch (type) {
      case GroupSpacing.HORIZONTAL:
        this.onHorizontalSpacing().catch((e) => {
          console.error(e);
        });
        break;
      case GroupSpacing.VERTICAL:
        this.onVerticalGroupSpacing().catch((e) => {
          console.error(e);
        });
        break;
      default:
        throw new Error('unhandled spacing type for grouped items');
    }
  }

  public onDuplicate(): void {
    const selectedItems = this.page.getSelectedItems();
    if (selectedItems.length !== 0) {
      this.duplicateSelection(selectedItems, 15, 15);
      return;
    }

    const selectedAudioItem = this.page.poster.audioClips.getSelectedAudioItem();

    if (!selectedAudioItem) {
      return;
    }

    this.duplicateAudioItem(selectedAudioItem);
  }

  public duplicateAudioItem(audioItem: AudioItem): void {
    void this.page.items.addItems.addAudioItemFromAudioItemObject(audioItem);
  }

  public orderGraphicItems(graphicItems: Array<ItemType>): Array<ItemType> {
    const views = this.page.fabricCanvas.getObjects();
    const rearrangeItems = [];
    const graphicItemsNotOnFabric = [];
    const orderedGraphicItems = [];
    const graphicItemZIndexHashMap: Record<string, number> = {};

    for (let index = 0; index < views.length; index += 1) {
      const uid = views[index].__PMWID;
      graphicItemZIndexHashMap[uid] = index;
    }

    for (let index = 0; index < graphicItems.length; index += 1) {
      if (graphicItemZIndexHashMap[graphicItems[index].uid] !== undefined) {
        rearrangeItems[graphicItemZIndexHashMap[graphicItems[index].uid]] = graphicItems[index];
      } else {
        graphicItemsNotOnFabric.push(graphicItems[index]);
      }
    }

    for (let index = 0; index < rearrangeItems.length; index += 1) {
      if (rearrangeItems[index]) {
        orderedGraphicItems.push(rearrangeItems[index]);
      }
    }

    for (let index = 0; index < graphicItemsNotOnFabric.length; index += 1) {
      orderedGraphicItems.push(graphicItemsNotOnFabric[index]);
    }

    return orderedGraphicItems;
  }

  public duplicateSelection(graphicItems: Array<ItemType>, x = 15, y = 15): void {
    if (graphicItems.length === 0) {
      return;
    }

    const items: Array<ItemType> = [];
    const itemsToDuplicate = this.orderGraphicItems(graphicItems);

    for (let index = 0; index < itemsToDuplicate.length; index += 1) {
      const duplicateItem = cloneDeep(itemsToDuplicate[index]);
      duplicateItem.uid = uuidv4();
      duplicateItem.isNew = true;

      if (duplicateItem.isSlideshow()) {
        duplicateItem.selectedSlideUID = '';

        for (const [slideId, slide] of Object.entries(duplicateItem.slides.slidesHashMap)) {
          slide.uid = uuidv4();
          duplicateItem.slides.slidesHashMap[slide.uid] = slide;
          delete duplicateItem.slides.slidesHashMap[slideId];

          const slideIndex = duplicateItem.slides.slidesOrder.indexOf(slideId);
          if (slideIndex !== -1) {
            duplicateItem.slides.slidesOrder[slideIndex] = slide.uid;
          }

          if (slide.isTextSlide()) {
            slide.background.uid = uuidv4();
          }
        }
      } else if (duplicateItem.isText()) {
        duplicateItem.background.uid = uuidv4();
      }

      if (x || y) {
        duplicateItem.x += x;
        duplicateItem.y += y;
      }

      if (duplicateItem.isMenu()) {
        duplicateItem.updateCopiedItems(duplicateItem.itemIds);
      }

      items.push(duplicateItem);
    }

    void this.page.items.addItems.addItemsFromItemType(items);
  }

  public selectFabricObjects(fabricObjects: Array<FabricObject>): void {
    this.discardActiveObject();
    if (fabricObjects.length === 1) {
      this.setActiveObject(fabricObjects[0]);
    } else if (fabricObjects.length > 1) {
      const selection = new ActiveSelection(fabricObjects, {
        canvas: this.page.fabricCanvas,
      });
      this.setActiveObject(selection);
    }
    this.page.fabricCanvas.requestRenderAll();
  }

  public async resizeActiveSelection(e: Event, key = ResizeSelectionKey.UP): Promise<void> {
    let direction = 1;
    if (key === ResizeSelectionKey.DOWN) {
      direction = -1;
    }
    const activeItems = this.page.getSelectedItems();
    if (activeItems.length === 0 || (activeItems && activeItems[0].isLocked())) {
      return;
    }

    if (activeItems.length === 1) {
      e.preventDefault();
    }

    const scale = RESIZE_SELECTION_SCALE.DEFAULT * direction;
    const textFontSize = RESIZE_SELECTION_SCALE.TEXT * direction;

    if (activeItems.length === 1) {
      if (
        activeItems[0].isText() &&
        ((direction === -1 && activeItems[0].textStyles.fontSize > FONT_SIZE_MIN) || (direction === 1 && activeItems[0].textStyles.fontSize < FONT_SIZE_MAX))
      ) {
        let newFontSize = activeItems[0].textStyles.fontSize + textFontSize;
        if (newFontSize < FONT_SIZE_MIN) {
          newFontSize = FONT_SIZE_MIN;
        } else if (newFontSize > FONT_SIZE_MAX) {
          newFontSize = FONT_SIZE_MAX;
        }

        void activeItems[0].updateFromObject({
          textStyles: {
            fontSize: newFontSize,
          },
          width: activeItems[0].width * (newFontSize / activeItems[0].textStyles.fontSize),
        });
      } else if (
        !activeItems[0].isText() &&
        ((activeItems[0].scaleX > RESIZE_SELECTION_SCALE.DEFAULT && activeItems[0].scaleY > RESIZE_SELECTION_SCALE.DEFAULT) || direction === 1)
      ) {
        void activeItems[0].updateFromObject({
          scaleX: activeItems[0].scaleX + scale,
          scaleY: activeItems[0].scaleY + scale,
        });
      }
    } else if (activeItems.length > 1) {
      const group = this.getActiveObject();
      if (!group) {
        return;
      }
      if ((group.scaleX > RESIZE_SELECTION_SCALE.DEFAULT && group.scaleY > RESIZE_SELECTION_SCALE?.DEFAULT) || direction === 1) {
        await this.updateGroupSelection({
          scaleX: group.scaleX + scale,
          scaleY: group.scaleY + scale,
        });
      }
    }
  }

  public async updateGroupSelection(changes: Record<string, any>, undoable = true): Promise<void> {
    const group = this.getActiveObject();
    if (group instanceof Group) {
      group.set(changes);
      await this.updateVisuals(group.getObjects(), group, undoable);
    }
  }

  public setActiveObject(object: FabricObject): void {
    if (this.page.fabricCanvas instanceof Canvas) {
      this.page.fabricCanvas.setActiveObject(object);
      this.page.fabricCanvas.requestRenderAll();
    }
  }

  public getActiveObject(): FabricObject | undefined {
    return this.page.fabricCanvas instanceof Canvas ? this.page.fabricCanvas.getActiveObject() : undefined;
  }

  public discardActiveObject(): void {
    if (this.page.fabricCanvas instanceof Canvas) {
      this.page.fabricCanvas.discardActiveObject();
    }
  }

  public getActiveObjects(): Array<FabricObject> {
    return this.page.fabricCanvas instanceof Canvas ? this.page.fabricCanvas.getActiveObjects() : [];
  }

  public getSelectedViews(): Array<FabricObject> {
    const o = this.getActiveObject();
    let views: Array<FabricObject> = [];
    if (o) {
      if (o instanceof ActiveSelection) {
        views = o.getObjects();
      } else {
        views[0] = o;
      }
    }
    return views;
  }
}
