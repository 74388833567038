import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {RGB} from '@Utils/color.util';
import {rgbToHexString} from '@Utils/color.util';
import {getBackgroundColor} from '@PosterWhiteboard/libraries/text.library';
import {addFonts, FONT_LOAD_STATUS, fontsRequestedMap, getFontFamilyNameForVariations, isBoldVariationAvaliableForFont} from '@Libraries/font-library';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {FabricTextItemStyles, TextStylesObject} from '@PosterWhiteboard/classes/text-styles.class';
import {BOLD_STROKE_WIDTH_FACTOR, DEFAULT_STROKE_WIDTH, TEXT_OUTLINE_STROKE_WIDTH_FACTOR, TextStyles} from '@PosterWhiteboard/classes/text-styles.class';
import {LayoutBackgroundTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {ElementDataType} from '@Libraries/add-media-library';
import {openPosterEditorTabsTextModal} from '@Modals/poster-editor-tabs-text-modal';
import {StackLayout} from '@PosterWhiteboard/items/tabs-item/stack-layout';
import type {FabricObject} from '@postermywall/fabricjs-2';
import {Group, FixedLayout, IText, LayoutManager, Line} from '@postermywall/fabricjs-2';
import type {DeepPartial} from '@/global';

export interface TabsItemObject extends BaseItemObject {
  text: string;
  numTabs: number;
  separatorColor: RGB;
  separatorType: number;
  textStyles: TextStylesObject;
  backgroundType: number;
  backgroundColor: RGB;
  backgroundColorAlpha: number;
}

export enum SeparatorType {
  NONE = 0,
  SOLID = 1,
  DASHED = 2,
}

export class TabsItem extends Item {
  declare fabricObject: Group;

  public gitype = ITEM_TYPE.TAB;
  public text = '';
  public numTabs = 0;
  public separatorColor: RGB = [0, 0, 0];
  public separatorType: SeparatorType = SeparatorType.NONE;
  public backgroundType: LayoutBackgroundTypes = LayoutBackgroundTypes.NONE;
  public backgroundColor: RGB = [184, 184, 184];
  public backgroundColorAlpha = 1;
  public textStyles: TextStyles;
  public layout!: StackLayout;
  public padding = 25;

  constructor(page: Page) {
    super(page);
    this.textStyles = new TextStyles();
    this.layout = new StackLayout(this);
  }

  public async init(): Promise<void> {
    if (!this.isInitialzed) {
      await this.initFabricObject();
      this.onInitItem();
      this.isInitialzed = true;
    }
  }

  public async initFabricObject(): Promise<void> {
    return new Promise((resolve, reject) => {
      addFonts(
        [this.textStyles.getFontFamilyToLoad()],
        async () => {
          await super.initFabricObject();
          resolve();
        },
        reject
      );
    });
  }

  protected async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.fabricObject.set({
      backgroundColor: getBackgroundColor(this),
    });

    const textStyles = await this.getLoadedTextStyles(this.fabricObject.width, this.fabricObject.height);

    this.addOrRemoveItems();
    this.updateTextObjects({
      ...textStyles,
      text: this.text,
    });
    this.adjustFontSize();

    this.removeSeparators();
    if (this.separatorType !== SeparatorType.NONE) {
      this.addSeparators();
    }

    await this.layout.doLayout();
    this.applyFontVariationOnTabs();
    if (!this.textStyles.isBold) {
      this.applyTextStrokeOnTabs();
    }
    this.fabricObject.setCoords();
  }

  private updateTextObjects(values: Record<string, any>): void {
    for (const textObject of this.getTextFabricObjects()) {
      textObject.set(values);
    }
  }

  protected getTextStyles(fillWidth: number, fillHeight: number): Partial<FabricTextItemStyles> {
    const textStyles: Partial<FabricTextItemStyles> = this.textStyles.getTextStyles(fillWidth, fillHeight);
    return this.filterTextStylesForTab(textStyles);
  }

  protected async getLoadedTextStyles(fillWidth: number, fillHeight: number): Promise<Partial<FabricTextItemStyles>> {
    const textStyles: Partial<FabricTextItemStyles> = await this.textStyles.getLoadedTextStyles(fillWidth, fillHeight);
    return this.filterTextStylesForTab(textStyles);
  }

  protected filterTextStylesForTab(textStyles: Partial<FabricTextItemStyles>): Partial<FabricTextItemStyles> {
    let filteredTextStyles = textStyles;
    delete filteredTextStyles?.fontSize;

    const fontFamilyWithVariation = this.textStyles.getFontFamilyToLoad();
    const isFontLoaded = fontsRequestedMap[fontFamilyWithVariation] === FONT_LOAD_STATUS.LOADED;
    const isBoldApplied = isFontLoaded ? isBoldVariationAvaliableForFont(this.textStyles.fontFamily) : false;

    if (this.textStyles.isBold && !isBoldApplied) {
      filteredTextStyles = {
        ...filteredTextStyles,
        strokeWidth: BOLD_STROKE_WIDTH_FACTOR,
      };
    }
    return filteredTextStyles;
  }

  protected async getFabricObjectForItem(): Promise<Group> {
    return new Promise((resolve) => {
      const groupItems = [];
      for (let i = 0; i < this.numTabs; i++) {
        groupItems.push(
          new IText(this.text, {
            angle: 90,
          })
        );
      }
      // set the value of padding to be used by tabs,
      // 0.045 is the poster width to padding ratio for normal (600 x 900) poster
      let currentAspectRatio = this.y / this.width;
      if (currentAspectRatio > 1) {
        currentAspectRatio = 1 / currentAspectRatio;
      }
      this.padding = this.width * 0.045 * currentAspectRatio;
      // 25 is the minimum value of padding to be used by tabs.
      if (this.padding < 25) {
        this.padding = 25;
      }

      resolve(
        new Group(groupItems, {
          width: this.width,
          height: this.height,
          perPixelTargetFind: true,
          layoutManager: new LayoutManager(new FixedLayout()),
        })
      );
    });
  }

  /**
   * Removes line separators from the view component(tabs). This function assumes that lines are always added at the end
   * of the objects array.
   */
  removeSeparators(): void {
    const lineObjects = this.fabricObject.getObjects().filter((item) => {
      return item instanceof Line;
    });
    this.fabricObject.remove(...lineObjects);
  }

  /**
   * Updates the number of tabs(text items) in the view using latest value from the model
   */
  protected addOrRemoveItems(): void {
    let i;
    const oldNumTabs = this.getTextFabricObjects().length;
    const newNumTabs = this.numTabs;
    let o;
    const itemsToAdd: Array<IText> = [];

    if (oldNumTabs !== newNumTabs) {
      const n = newNumTabs - oldNumTabs;
      if (n < 0) {
        o = this.getTextFabricObjects();
        this.fabricObject.remove(...o.slice(n));
      } else {
        for (i = 0; i < n; i++) {
          itemsToAdd.push(
            new IText('', {
              angle: 90,
            })
          );
        }
        this.addItemsToGroupWithOriginalValues(itemsToAdd);
      }
    }
  }

  /**
   * Adjusts the font size of the text items in tabs and updates the view
   */
  protected adjustFontSize(): void {
    const items = this.getTextFabricObjects();
    const totalItems = items.length;
    const firstTab = items[0];
    const spaceR = firstTab._textLines.length * totalItems;
    let fontSize = (this.fabricObject.width - totalItems * this.padding) / spaceR;
    const maxFontSize = 100;
    const minFontSize = 8;

    if (fontSize > maxFontSize) {
      fontSize = maxFontSize;
    }
    if (fontSize <= minFontSize) {
      fontSize = minFontSize;
    }

    // update font size and view dimensions
    this.updateTextObjects({
      fontSize,
    });
    this.fabricObject.set({height: firstTab.calcTextWidth() + this.fabricObject.padding * 2});
  }

  private getTextFabricObjects(): Array<IText> {
    return this.fabricObject.getObjects().filter((item) => {
      return item instanceof IText;
    }) as IText[];
  }

  /**
   * Adds line separator between the tabs
   */
  protected addSeparators(): void {
    const items = this.getTextFabricObjects();
    const firstTab = items[0];
    const itemsCount = items.length;
    let style: Array<number> = [];
    const strokeWidth = firstTab.fontSize * 0.05;
    const lineLength = firstTab.calcTextWidth();
    const lineColor = rgbToHexString(this.separatorColor, 1);
    const newFabricLines: Array<Line> = [];

    if (this.separatorType === SeparatorType.DASHED) {
      // d represents the distance in px between dashes in a dashed line.
      const d = firstTab.fontSize * 0.2;
      style = [d, d];
    }
    for (let i = 0; i < itemsCount - 1; i++) {
      newFabricLines.push(
        new Line([0, 0, 0, lineLength], {
          angle: 0,
          stroke: lineColor,
          strokeWidth,
          strokeDashArray: style,
          strokeLineCap: 'round',
        })
      );
    }

    this.addItemsToGroupWithOriginalValues(newFabricLines);
  }

  /**
   * Fabric sets the scales of add item such that the final scale inculding its group equals to that
   * We don't want that for layouts so set group scale 1 so that the add items scale doesn't change and
   * then restore the group scale
   * @param items
   */
  addItemsToGroupWithOriginalValues(items: Array<FabricObject>): void {
    const {scaleX, scaleY, angle} = this.fabricObject;
    this.fabricObject.set({
      scaleX: 1,
      scaleY: 1,
      angle: 0,
    });
    this.fabricObject.add(...items);
    this.fabricObject.set({
      scaleX,
      scaleY,
      angle,
    });
  }

  /**
   * apply fabric styles on tabs
   */
  protected applyFontVariationOnTabs(): void {
    const items = this.getTextFabricObjects();

    for (const item of items) {
      const textStyles = this.getTextStyles(item.width, item.height);
      item.set(textStyles);
      this.applyTextEmphasisStylesOnTabs(item);
    }
  }

  /**
   * apply text stroke on tabs
   */
  applyTextStrokeOnTabs(): void {
    for (const item of this.getTextFabricObjects()) {
      if (this.textStyles.stroke) {
        item.set({
          stroke: rgbToHexString(this.textStyles.strokeColor, 1),
          strokeLineJoin: 'round',
          paintFirst: 'stroke',
          strokeWidth: item.fontSize * this.textStyles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
        });
      } else {
        item.set({
          stroke: null,
          strokeLineJoin: 'miter',
          paintFirst: 'fill',
          strokeWidth: DEFAULT_STROKE_WIDTH,
        });
      }
    }
  }

  /**
   * apply fabric underline/linethrough style on tabs
   * param {object} tab
   */
  applyTextEmphasisStylesOnTabs(tab: IText): void {
    if (tab.stroke && !this.textStyles.stroke) {
      tab.set({
        strokeWidth: tab.strokeWidth * tab.fontSize,
      });
    }
    tab.set({underline: this.textStyles.underLine});
    tab.set({linethrough: this.textStyles.lineThrough});
  }

  public toObject(): TabsItemObject {
    return {
      ...super.toObject(),
      text: this.text,
      textStyles: this.textStyles.toObject(),
      numTabs: this.numTabs,
      separatorColor: this.separatorColor,
      separatorType: this.separatorType,
      backgroundType: this.backgroundType,
      backgroundColor: this.backgroundColor,
      backgroundColorAlpha: this.backgroundColorAlpha,
    };
  }

  public copyVals(obj: DeepPartial<TabsItemObject>): void {
    const {textStyles, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.textStyles.copyVals(textStyles);
  }

  public getFonts(withVariation: boolean): Array<string> {
    return [withVariation ? getFontFamilyNameForVariations(this.textStyles.fontFamily, this.textStyles.isBold, this.textStyles.isItalic) : this.textStyles.fontFamily];
  }

  public hasBackground(): boolean {
    return this.backgroundType !== LayoutBackgroundTypes.NONE;
  }

  public getColors(): Array<RGB> {
    let colors = super.getColors();

    if (this.textStyles.fill.hasFill()) {
      colors = [...colors, ...this.textStyles.fill.fillColor];
    }

    if (this.hasBackground()) {
      colors.push(this.backgroundColor);
    }

    return colors;
  }

  protected onItemDoubleTapped(): void {
    openPosterEditorTabsTextModal();
  }
}

export const addTabsToPoster = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.addNewItems([
    {
      type: ElementDataType.TAB,
    },
  ]);
};
