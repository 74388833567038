import type {Page} from '@PosterWhiteboard/page/page.class';
import type {ItemObject, ItemType, SlideItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {TextSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/text-slide-item.class';
import {ImageSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import {QRCodeItem} from '@PosterWhiteboard/items/qr-code-item.class';
import {VideoItem} from '@PosterWhiteboard/items/video-item/video-item.class';
import {FancyTextItem} from '@PosterWhiteboard/items/fancy-text-item/fancy-text-item.class';
import {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import {ImageItem} from '@PosterWhiteboard/items/image-item/image-item.class';
import {VectorItem} from '@PosterWhiteboard/items/vector-item/vector-item.class';
import {StickerItem} from '@PosterWhiteboard/items/sticker-item.class';
import {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {TabsItem} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import {type SlideItem, SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import type {DeepPartial} from '@/global';

export const createItemFromObject = async (page: Page, itemObject: DeepPartial<ItemObject>): Promise<ItemType> => {
  if (!itemObject.gitype) {
    console.error('Need gitype to initiailize item');
    throw new Error('Need gitype to initiailize item');
  }

  const ItemClass = getItemClassForType(itemObject.gitype);
  const item = new ItemClass(page);
  const formattedItemObject = await item.formatItemObjectWithDefaultValues(itemObject);
  await item.updateFromObject(formattedItemObject, {
    updateRedux: false,
    undoable: false,
  });
  return item;
};

export const cloneItem = (page: Page, item: ItemType): ItemType => {
  const itemObject = item.toObject();
  const ItemClass = getItemClassForType(itemObject.gitype);
  const clone = new ItemClass(page);
  clone.copyVals(itemObject);
  return clone;
};

const getItemClassForType = (itemType: ITEM_TYPE) => {
  switch (itemType) {
    case ITEM_TYPE.QR_CODE:
      return QRCodeItem;

    case ITEM_TYPE.VIDEO:
      return VideoItem;

    case ITEM_TYPE.FANCY_TEXT:
      return FancyTextItem;

    case ITEM_TYPE.TEXT:
      return TextItem;

    case ITEM_TYPE.IMAGE:
      return ImageItem;

    case ITEM_TYPE.VECTOR:
      return VectorItem;

    case ITEM_TYPE.STICKER:
      return StickerItem;

    case ITEM_TYPE.TABLE:
      return TableItem;

    case ITEM_TYPE.MENU:
      return MenuItem;

    case ITEM_TYPE.TAB:
      return TabsItem;

    case ITEM_TYPE.SLIDESHOW:
      return SlideshowItem;

    case ITEM_TYPE.TRANSCRIPT:
      return TranscriptItem;

    default:
      throw new Error(`Couldn't load item. Unknown type: ${itemType}`);
  }
};
const getSlideItemClassForType = (itemType: ITEM_TYPE): typeof TextSlideItem | typeof ImageSlideItem | typeof VideoSlideItem => {
  switch (itemType) {
    case ITEM_TYPE.TEXTSLIDE:
      return TextSlideItem;

    case ITEM_TYPE.IMAGESLIDE:
      return ImageSlideItem;

    case ITEM_TYPE.VIDEOSLIDE:
      return VideoSlideItem;

    default:
      throw new Error(`Couldn't load slide item. Unknown type: ${itemType}`);
  }
};
export const createSlideItemFromObject = async (page: Page, slideshow: SlideshowItem, itemObject: DeepPartial<SlideItemObject>): Promise<SlideItem> => {
  if (!itemObject.gitype) {
    throw new Error('Need gitype to initiailize slide item');
  }

  const ItemClass = getSlideItemClassForType(itemObject.gitype);
  const item = new ItemClass(page, slideshow);
  await item.updateFromObject(itemObject, {
    updateRedux: false,
    undoable: false,
    doInvalidate: false,
  });
  return item;
};
