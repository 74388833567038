import type {TPointerEvent, Transform} from '@postermywall/fabricjs-2';
import {InteractiveFabricObject, Textbox, Control, Group, FabricObject, controlsUtils} from '@postermywall/fabricjs-2';
import {angleBetweenTwoPoints, degreesToRadians, distanceBetweenTwoPoints} from '@Utils/math.util';
import md5 from 'md5';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {isMobile} from 'react-device-detect';
import {loadImageAsync} from '@Utils/image.util';
import verticalRectangleSVG from './poster-item-control-svgs/vertical-rectangle.svg';
import horizontalRectangleSVG from './poster-item-control-svgs/horizontal-rectangle.svg';
import lockSVG from './poster-item-control-svgs/lock.svg';
import rotateSVG from './poster-item-control-svgs/rotate.svg';
import nextSlideSVG from './poster-item-control-svgs/next-slide.svg';
import previousSlideSVG from './poster-item-control-svgs/previous-slide.svg';
import pmwBmBtnSVG from './poster-item-control-svgs/pmw-bm-btn.svg';
import squareSVG from './poster-item-control-svgs/square.svg';

const controlImgs: Record<string, HTMLImageElement> = {};

interface DrawControlOptions {
  offsetX?: number;
  offsetY?: number;
  maxSide?: number;
  width?: number;
  height?: number;
  opacity?: number;
}

export interface OnResizeParams {
  e: TPointerEvent;
  delta: number;
}

export const enum ItemControlOption {
  SLIDE_BTN_ACTION_NAME = 'slideBtnClicked',
}

export enum ItemControlAction {
  SLIDE_BTN_ACTION_NAME = 'slideBtnClicked',
  WIDTH_BTN_ACTION_NAME = 'widthModified',
  HEIGHT_BTN_ACTION_NAME = 'heightModified',
}

export interface CustomControl {
  key: string;
  control: Control;
}

export enum ItemBorderColor {
  STATIC_ITEM = 'rgba(63,188,231,1)',
  DYNAMIC_ITEM = 'rgba(149, 119, 231,1)',
}

export const DEFAULT_CIRCLE_CONTROL_SIZE = 35;
const DESKTOP_SELECTION_SIZE = 24;
const MOBILE_SELECTION_SIZE = 40;

export const ITEM_CONTROL_DIMENSIONS = {
  BORDER_THICKNESS: 2,
  DEFAULT_RECTANGLE_CONTROL_SIZE: 24,
  BOTTOM_MIDDLE_BUTTON_SIZE: 200,
  ROTATING_CONTROL_OFFSET: -30,
  UNLOCK_BUTTON_OFFSETY: -30,
  BOTTOM_BUTTONS_OFFSET: 28,
  DISABLED_OPACITY: 0.7,
  PMW_CONTROL_PADDING: 11,
};

export const initDefaultFabricControls = (): void => {
  InteractiveFabricObject.ownDefaults = {
    ...InteractiveFabricObject.ownDefaults,
    borderColor: ItemBorderColor.STATIC_ITEM,
    borderOpacityWhenMoving: 1,
    borderScaleFactor: ITEM_CONTROL_DIMENSIONS.BORDER_THICKNESS,
    cornerSize: getCornerSelectableSize(),
    padding: ITEM_CONTROL_DIMENSIONS.PMW_CONTROL_PADDING,
    transparentCorners: false,
    lockScalingFlip: true,
    snapAngle: 45,
    snapThreshold: 1,
    perPixelTargetFind: true,
    noScaleCache: false,
  };

  Group.ownDefaults = {
    ...Group.ownDefaults,
    perPixelTargetFind: false,
  };

  initControlUI();
};

export const getPmwBmBtnControl = (onClick: () => void): CustomControl => {
  const key = 'pmwBmBtn';
  return {
    key,
    control: new Control({
      x: 0,
      y: 0.5,
      offsetY: ITEM_CONTROL_DIMENSIONS.BOTTOM_BUTTONS_OFFSET,
      visible: false,
      cursorStyle: 'pointer',
      render: renderControl.bind(this, key),
      mouseUpHandler: (): void => {
        onClick();
      },
    }),
  };
};

export const getPmwMbControl = (onResize: (params: OnResizeParams) => void): CustomControl => {
  const key = 'pmwMb';
  return {
    key,
    control: new Control({
      x: 0,
      y: 0.5,
      visible: false,
      cursorStyleHandler: controlsUtils.scaleCursorStyleHandler,
      actionName: ItemControlAction.HEIGHT_BTN_ACTION_NAME,
      render: renderControl.bind(this, key),
      actionHandler: (e: TPointerEvent, transformData: Transform): boolean => {
        if (!transformData.corner) {
          return false;
        }

        const xDelta = getDeltaOnResizeWithBottomHandle(e, transformData);
        onResize({e, delta: xDelta});
        return true;
      },
    }),
  };
};

export const getPmwMtControl = (onResize: (params: OnResizeParams) => void): CustomControl => {
  const key = 'pmwMt';
  return {
    key,
    control: new Control({
      x: 0,
      y: -0.5,
      visible: false,
      cursorStyleHandler: controlsUtils.scaleCursorStyleHandler,
      actionName: ItemControlAction.HEIGHT_BTN_ACTION_NAME,
      render: renderControl.bind(this, key),
      actionHandler: (e: TPointerEvent, transformData: Transform): boolean => {
        if (!transformData.corner) {
          return false;
        }

        const xDelta = getDeltaOnResizeWithTopHandle(e, transformData);
        onResize({e, delta: xDelta});
        return true;
      },
    }),
  };
};

export const getPmwMrControl = (onResize: (params: OnResizeParams) => void): CustomControl => {
  const key = 'pmwMr';
  return {
    key,
    control: new Control({
      x: 0.5,
      y: 0,
      visible: false,
      cursorStyleHandler: controlsUtils.scaleCursorStyleHandler,
      actionName: ItemControlAction.WIDTH_BTN_ACTION_NAME,
      render: renderControl.bind(this, key),
      actionHandler: (e: TPointerEvent, transformData: Transform): boolean => {
        if (!transformData.corner) {
          return false;
        }

        const xDelta = getDeltaOnResizeWithRightHandle(e, transformData);
        onResize({e, delta: xDelta});
        return true;
      },
    }),
  };
};

export const getPmwMlControl = (onResize: (params: OnResizeParams) => void): CustomControl => {
  const key = 'pmwMl';
  return {
    key,
    control: new Control({
      x: -0.5,
      y: 0,
      visible: false,
      cursorStyleHandler: controlsUtils.scaleCursorStyleHandler,
      actionName: ItemControlAction.WIDTH_BTN_ACTION_NAME,
      render: renderControl.bind(this, key),
      actionHandler: (e: TPointerEvent, transformData: Transform): boolean => {
        if (!transformData.corner) {
          return false;
        }

        const xDelta = getDeltaOnResizeWithLeftHandle(e, transformData);
        onResize({e, delta: xDelta});
        return true;
      },
    }),
  };
};

/**
 * Handler for when the left pmw button for resizing width is used
 * @see https://docs.google.com/document/d/13s4P6QlYpmW9Oktp31LvcCMHePGc-SaxTFb19K0bIj0/edit#
 */
const getDeltaOnResizeWithLeftHandle = (e: TPointerEvent, transformData: Transform): number => {
  const delta = getDeltaFromClickToControl(e, transformData.target, transformData.corner);
  const angle = getAngleFromClickToControl(e, transformData.target, transformData.corner);
  return delta * Math.cos(degreesToRadians(angle));
};

export const getDeltaOnResizeWithRightHandle = (e: TPointerEvent, transformData: Transform): number => {
  const delta = getDeltaFromClickToControl(e, transformData.target, transformData.corner);
  const angle = getAngleFromClickToControl(e, transformData.target, transformData.corner);
  return delta * Math.cos(degreesToRadians(angle + 180));
};

export const getDeltaOnResizeWithTopHandle = (e: TPointerEvent, transformData: Transform): number => {
  const delta = getDeltaFromClickToControl(e, transformData.target, transformData.corner);
  const angle = getAngleFromClickToControl(e, transformData.target, transformData.corner);
  return delta * Math.sin(degreesToRadians(angle));
};

export const getDeltaOnResizeWithBottomHandle = (e: TPointerEvent, transformData: Transform): number => {
  const delta = getDeltaFromClickToControl(e, transformData.target, transformData.corner);
  const angle = getAngleFromClickToControl(e, transformData.target, transformData.corner);
  return delta * Math.sin(degreesToRadians(angle + 180));
};

export const getDeltaFromClickToControl = (e: TPointerEvent, fabricObject: FabricObject, corner: string): number => {
  const {canvas} = fabricObject;
  if (!canvas) {
    return 0;
  }

  const oCoords = fabricObject.calcOCoords();
  const controlX = (oCoords[corner].x - canvas.viewportTransform[4]) / canvas.getZoom();
  const controlY = (oCoords[corner].y - canvas.viewportTransform[5]) / canvas.getZoom();
  const pointer = canvas.getPointer(e);

  return distanceBetweenTwoPoints(pointer.x, pointer.y, controlX, controlY);
};

export const getAngleFromClickToControl = (e: TPointerEvent, fabricObject: FabricObject, corner: string): number => {
  const {canvas} = fabricObject;
  if (!canvas) {
    return 0;
  }

  const pointer = canvas.getPointer(e);
  const oCoords = fabricObject.calcOCoords();
  const controlX = (oCoords[corner].x - canvas.viewportTransform[4]) / canvas.getZoom();
  const controlY = (oCoords[corner].y - canvas.viewportTransform[5]) / canvas.getZoom();
  let pointToControlAngle = angleBetweenTwoPoints(pointer.x, pointer.y, controlX, controlY) - fabricObject.angle;

  if (pointToControlAngle < 0) {
    pointToControlAngle = 360 + pointToControlAngle;
  }

  return pointToControlAngle;
};

export const pmwBtnsCursorStyleHandler = (e: TPointerEvent, control: Control): string => {
  return control.disabled ? 'not-allowed' : 'pointer';
};

export const getUnlockControl = (): CustomControl => {
  const key = 'unlockBtn';
  return {
    key,
    control: new Control({
      x: 0,
      y: -0.5,
      offsetY: ITEM_CONTROL_DIMENSIONS.UNLOCK_BUTTON_OFFSETY,
      visible: false,
      withConnection: true,
      cursorStyle: 'pointer',
      mouseUpHandler: onUnlockMouseUp.bind(this),
      render: renderControl.bind(this, key),
    }),
  };
};

export const onUnlockMouseUp = (e: TPointerEvent, transformData: Transform): boolean => {
  void window.posterEditor?.whiteboard?.getItemForId(transformData.target.__PMWID)?.updateFromObject({
    lockMovement: false,
  });
  return true;
};

export const initControlUI = (): void => {
  FabricObject.createControls = (): {controls: Record<string, Control>} => {
    return {controls: getModifedControlsForPMW(controlsUtils.createObjectDefaultControls())};
  };

  Textbox.createControls = (): {controls: Record<string, Control>} => {
    return {controls: getModifedControlsForPMW(controlsUtils.createTextboxDefaultControls())};
  };
};

const getModifedControlsForPMW = (controls: Record<string, Control>): Record<string, Control> => {
  for (const [controlId, control] of Object.entries(controls)) {
    control.render = renderControl.bind(this, controlId);

    if (controlId === 'mtr') {
      control.withConnection = false;
      control.offsetY = ITEM_CONTROL_DIMENSIONS.ROTATING_CONTROL_OFFSET;
    }

    if (controlId === 'ml' || controlId === 'mt' || controlId === 'mr' || controlId === 'mb') {
      control.visible = false;
    }
  }
  return controls;
};

const getCornerSelectableSize = (): number => {
  return isMobile ? MOBILE_SELECTION_SIZE : DESKTOP_SELECTION_SIZE;
};

export const renderControl = (key: string, ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: FabricObject | Group): void => {
  const middleCornerButtonsSizeRatio = 1.45;
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();

  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(degreesToRadians(fabricObject.angle));

  switch (key) {
    case 'mb':
    case 'mt':
      drawControlIcon(horizontalRectangleSVG as string, ctx, {maxSide: ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE * middleCornerButtonsSizeRatio});
      break;

    case 'ml':
    case 'mr':
    case 'pmwMl':
    case 'pmwMr':
      drawControlIcon(verticalRectangleSVG as string, ctx, {maxSide: Math.round(ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE * middleCornerButtonsSizeRatio)});
      break;

    case 'pmwMt':
    case 'pmwMb':
      drawControlIcon(horizontalRectangleSVG as string, ctx, {maxSide: Math.round(ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE * middleCornerButtonsSizeRatio)});
      break;

    case 'mtr':
      drawControlIcon(rotateSVG as string, ctx, {maxSide: Math.round(DEFAULT_CIRCLE_CONTROL_SIZE)});
      break;

    case 'pmwBmBtn':
      drawPmwBmBtn(ctx, fabricObject);
      break;

    case 'unlockBtn':
      drawControlIcon(lockSVG as string, ctx, {maxSide: Math.round(DEFAULT_CIRCLE_CONTROL_SIZE)});
      break;

    case 'pmwPreviousSlideBtn':
      if (currentPage) {
        const item = currentPage.items.getItemForFabricObject(fabricObject) as SlideshowItem;

        item.fabricObject.controls[key].disabled = !item.slides.hasPreviousSlide();

        const opts: DrawControlOptions = {maxSide: DEFAULT_CIRCLE_CONTROL_SIZE};
        if (item.fabricObject.controls[key].disabled) {
          opts.opacity = ITEM_CONTROL_DIMENSIONS.DISABLED_OPACITY;
        }
        drawControlIcon(previousSlideSVG as string, ctx, opts);
      }
      break;

    case 'pmwNextSlideBtn':
      if (currentPage) {
        const item = currentPage.items.getItemForFabricObject(fabricObject) as SlideshowItem;

        item.fabricObject.controls[key].disabled = !item.slides.hasNextSlide();

        const opts: DrawControlOptions = {maxSide: DEFAULT_CIRCLE_CONTROL_SIZE};
        if (item.fabricObject.controls[key].disabled) {
          opts.opacity = ITEM_CONTROL_DIMENSIONS.DISABLED_OPACITY;
        }
        drawControlIcon(nextSlideSVG as string, ctx, opts);
      }
      break;

    default:
      drawControlIcon(squareSVG as string, ctx, {maxSide: ITEM_CONTROL_DIMENSIONS.DEFAULT_RECTANGLE_CONTROL_SIZE});
      break;
  }

  ctx.restore();
};

/**
 * Draws the bottom middle pmw btn
 */
const drawPmwBmBtn = (ctx: CanvasRenderingContext2D, fabricObject: FabricObject): void => {
  const TEXT_HEIGHT = 11; // This value is attained by hit and trail
  const ICON_MARGIN_RIGHT = 3;
  const BUTTON_RIGHT_LEFT_PADDING = 35;
  const BUTTON_TOP_BOTTOM_PADDING = 30;
  const ICON_MAX_SIDE = TEXT_HEIGHT + 4;
  const textFont = 'bold 14px Nunito Sans';
  const textMetrics = getTextMetrics(fabricObject.pmwBmBtnText, textFont);
  const textWidth = textMetrics?.width ?? 0;
  const buttonWidth = textWidth + ICON_MAX_SIDE + BUTTON_RIGHT_LEFT_PADDING;
  const buttonHeight = TEXT_HEIGHT + BUTTON_TOP_BOTTOM_PADDING;

  fabricObject.controls.pmwBmBtn.sizeX = buttonWidth;
  fabricObject.controls.pmwBmBtn.sizeY = buttonHeight;
  drawControlIcon(pmwBmBtnSVG as string, ctx, {width: buttonWidth, height: buttonHeight});
  drawControlIcon(fabricObject.pmwBmBtnIcon, ctx, {
    maxSide: ICON_MAX_SIDE,
    offsetX: -textWidth / 2 - ICON_MARGIN_RIGHT / 2,
  });

  ctx.font = textFont;
  ctx.fillStyle = '#4A4A4A';
  ctx.fillText(fabricObject.pmwBmBtnText, -textWidth / 2 + ICON_MAX_SIDE / 2 + ICON_MARGIN_RIGHT / 2, TEXT_HEIGHT / 2);
};

/**
 * Returns text metrics for text and font
 */
const getTextMetrics = (text: string, font: string): TextMetrics | undefined => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.font = font;
    return ctx.measureText(text);
  }
  return undefined;
};

export const drawControlIcon = (svgContent: string, ctx: CanvasRenderingContext2D, opts: DrawControlOptions): void => {
  if (!isImageLoadedForSvg(svgContent)) {
    loadImageForSvg(svgContent)
      .then(() => {
        window.posterEditor?.whiteboard?.getCurrentPage().fabricCanvas.requestRenderAll();
      })
      .catch((e) => {
        console.error(e);
      });
    return;
  }

  const controlImg = getImageForSvg(svgContent);
  let cornerWidth = 0;
  let cornerHeight = 0;
  const offsetX = typeof opts.offsetX !== 'undefined' ? opts.offsetX : 0;
  const offsetY = typeof opts.offsetY !== 'undefined' ? opts.offsetY : 0;

  if (typeof opts.maxSide !== 'undefined') {
    if (controlImg.width > controlImg.height) {
      cornerWidth = opts.maxSide;
      cornerHeight = cornerWidth * (controlImg.height / controlImg.width);
    } else {
      cornerHeight = opts.maxSide;
      cornerWidth = cornerHeight * (controlImg.width / controlImg.height);
    }
  } else if (typeof opts.width !== 'undefined' && typeof opts.height !== 'undefined') {
    cornerWidth = opts.width;
    cornerHeight = opts.height;
  }

  ctx.save();
  if (typeof opts.opacity !== 'undefined') {
    ctx.globalAlpha = opts.opacity;
  }
  ctx.drawImage(controlImg, offsetX - cornerWidth / 2, offsetY - cornerHeight / 2, cornerWidth, cornerHeight);
  ctx.restore();
};

const isImageLoadedForSvg = (svgContent: string): boolean => {
  const checkSum = md5(svgContent);
  return controlImgs[checkSum] !== undefined;
};

const loadImageForSvg = async (svgContent: string): Promise<void> => {
  const checkSum = md5(svgContent);
  if (!controlImgs[checkSum]) {
    controlImgs[checkSum] = await loadImageAsync(svgContent);
  }
};

const getImageForSvg = (svgContent: string): HTMLImageElement => {
  const checkSum = md5(svgContent);
  return controlImgs[checkSum];
};
